<template>
  <div class="page">
    <div class="main">
      <div class="imgs">
        <img src="/gongbo.png" alt="">
        <img src="/ngc.png" alt="">
        <img src="/pcgs.png" alt="">
      </div>

      <div class="wode-songpingdan">
        <div class="ws-top" @click="toDingdanLiebiao(-10)">
          <div class="ws-title">我的送评单</div>
          <div class="jiantou-right">
            全部
            <img class="youjiantou" src="/right.png" alt="">
          </div>
        </div>
        <div class="songpingdans">
          <div class="row" @click="toDingdanLiebiao(0)">
            <img style="width: 23px;" src="/daisongping.png" alt="">
            <div style="margin-top:2px;" class="img-title">已接收</div>
            <div class="amount" v-if="Number(headAmount.yijieshouAmount) < 999">{{ headAmount.yijieshouAmount }}</div>
            <div class="amount" v-else>999</div>
          </div>
          <div class="row"  @click="toDingdanLiebiao(1)">
            <img src="/pingjizhong.png" alt="">
            <div class="img-title">送评中</div>
            <div class="amount" v-if="Number(headAmount.songpingzhongAmount) < 999">{{ headAmount.songpingzhongAmount }}</div>
            <div class="amount" v-else>999</div>
          </div>
          <div class="row" @click="toDingdanLiebiao(2)">
            <img src="/daifukuan.png" alt="">
            <div class="img-title">评级中</div>
            <div class="amount" v-if="Number(headAmount.yipingjiAmount) < 999">{{ headAmount.yipingjiAmount }}</div>
            <div class="amount" v-else>999</div>
          </div>
          <div class="row" @click="toDingdanLiebiao(3)">
            <img src="/daifanhuan.png" alt="">
            <div class="img-title">已付款</div>
            <div class="amount" v-if="Number(headAmount.yifukuanAmount) < 999">{{ headAmount.yifukuanAmount }}</div>
            <div class="amount" v-else>999</div>
          </div>
          <div class="row" @click="toDingdanLiebiao(4)">
            <img style="width: 23px;" src="/yifanhuan.png" alt="">
            <div style="margin-top:4px;" class="img-title">已返还</div>
            <div class="amount" v-if="Number(headAmount.yifanhuanAmount) < 999">{{ headAmount.yifanhuanAmount }}</div>
            <div class="amount" v-else>999</div>
          </div>
        </div>
      </div>

      <div class="sec">
        <div class="title3">钱币评级鉴定流程：</div>
        <ul class="border">
          <li>客户把钱币邮寄或送到我们门店，备注留下您的姓名联系方式以及需要的送评公司。</li>
          <li>我们在签收拆包后给您建立送评清单，并且尽快送评。</li>
          <li>我们在签收拆包后给您建立送评清单，并且尽快送评。评级根据公司不同，周期也不同，系统有订单跟踪可以刷新到评级最新进度。</li>
          <li>钱币完成评级后，我们会及时通过微信联系您，安排取回您的评级币。</li>
        </ul>
        <p>我们支持PCGS.PMG.NGC.ASG.公博.华夏评级等评级，并且都提供对应的评级折扣优惠政策。</p>
      </div>
      <div class="btn">
        <button @click="goto2">评级服务介绍</button>
      </div>

      <!-- <div class="no" v-if="list.length == 0">
        <div class="list4">
          <div class="title2">我的送评清单</div>
        </div>
        <p style="text-align: center">您还没有送评记录</p>
      </div>
      <div class="list4" v-else>
        <div class="title2">我的送评清单</div>
        <div class="item" v-for="(item, index) in list" :key="index" @click="goto(item.id)">
          <div class="row">
            <div class="title">No. {{ item.order_sn }}</div>
            <div class="after" :style="{ color: item.statusObj.color }"><b>{{ item.statusObj.name }}</b></div>
          </div>
          <div class="row">
            <div class="col" style="flex: 1">
              <div class="row">
                <div class="left">送评数量：{{ item.amount }}</div>
              </div>
              <div class="row">
                <div class="left">接收日期：{{ item.created_at }}</div>
              </div>
              <div class="row">
                <div class="left">返还日期：{{ item.express_at }}</div>
              </div>
            </div>
            <img v-if="item.gs" style="width: 60px" :src="item.gs" alt="">
          </div>
        </div>
      </div> -->

      <div class="sec">
        <div class="title3">邮寄地址</div>
        <div class="border">
          <span id="text">上海市徐汇区云洲商厦二楼南08号<br />辉龙阁白小白<br />18505206136</span>
          <button class="copy" @click="copy">一键复制</button>
        </div>

      </div>



    </div>
  </div>
</template>

<style lang='scss' scord>
.imgs {
  background: #fff;
  padding: 0 30px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;

  img {
    width: 60px;
  }
}

.title2 {
  padding-bottom: 10px;
  font-weight: 500;
  color: #E02F21;
  font-size: 18px;
}

.no {
  margin: 20px 0;
  // text-align: center;
}

.page {
  background: #f5f5f5;
}

.sec {
  margin: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 3px;

  .title3 {
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0 15px 0;
  }

  .copy {
    float: right;
  }

  ul {
    list-style-type: circle !important;
    padding-left: 30px !important;
  }

  .border {
    background: #f5f5f5;
    border-radius: 3px;
    padding: 10px;
    line-height: 24px;

    button {
      margin-left: 10px;
      background: #F5C837;
      border: none;
      font-size: 12px;
      border-radius: 3px;
    }
  }
}

.btn {
  margin: 15px;
  // position: fixed;
  // bottom: 90px;
  // left: 0;

  button {
    background-color: #F5C837;
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 44px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    color: #000;
  }
}
.wode-songpingdan{
  margin: 15px;
  background: #eaaa0920;
  padding: 15px;
  border-radius: 3px;
  .ws-top{
    font-size: 18px;
    font-weight: 400;
    display: flex;
    .ws-title{
      flex: 1;
      display: flex;
      align-items: center;
    }
    .jiantou-right{
      display: flex;
      font-size: 14px;
      align-items: center;
    }
  }
  .songpingdans{
    margin-top: 10px;
    display: flex;
    .row{
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      img{
        width: 26px;
        margin-bottom: 5px;
      }
      .img-title{
        font-size: 14px;
      }
      .amount{
        position: absolute;
        right: 7px;
        top: -14px;
        color: #ffffff;
        background-color: red;
        text-align: center;
        font-size: 12px;
        width: 24px;
        padding: 1px;
        text-align: center;
        border-radius: 8px;
      }
    }
  }
}
</style>

<script>
import Clipboard from 'clipboard';
import { Notify } from 'vant';

export default {
  data() {
    return {
      shop_id: null,
      list: [],
      headAmount: {
        yijieshouAmount: 0,
        songpingzhongAmount: 0,
        yipingjiAmount: 0,
        yifukuanAmount: 0,
        yifanhuanAmount: 0,
      }
    };
  },

  async created() {
    this.shop_id = this.$route.params.shop_id || 1;
    this.getAmount()
    // const r = await this.$axios.get('/service/getMyList?shop_id=' + this.shop_id);
    // if (r.status == 1) {
    //   for (let item of r.list) {
    //     if (item.pingji_jigou == 'PCGS') {
    //       item.gs = '/pcgs.png'
    //     }
    //     else if (item.pingji_jigou == '公博') {
    //       item.gs = '/gongbo.png'
    //     }
    //     else if (item.pingji_jigou == 'NGC') {
    //       item.gs = '/ngc.png'
    //     }
    //     else {
    //       item.gs = ''
    //     }

    //     if (!item.statusObj) {
    //       item.statusObj = {
    //         color: '#555',
    //         name: '未知'
    //       }
    //     }
    //   }
    //   this.list = r.list
    // }
  },
  async mounted() {
    localStorage.setItem("backTo", this.$route.fullPath);
    if (!this.shop_id) return this.$router.push("/noshop");

    const r = await this.$axios.get("/shop/index?shop_id=" + this.shop_id);
    if (r.status == 1) {
      this.shop = r.shop;
    }

    const that = this;
    // --------------- 获取signature ---------------
    const fullPath = this.$config.domain + this.$route.fullPath;

    this.signature = await this.$axios.post("/getSignature", {
      url: fullPath,
    });

    wx.config({
      debug: false,
      appId: this.signature.appId,
      timestamp: Number(this.signature.timestamp),
      nonceStr: this.signature.nonceStr,
      signature: this.signature.signature,
      jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
    });

    wx.ready(function () {
      //--------------- 分享 ---------------
      wx.updateAppMessageShareData(
        {
          title: that.shop.shop_name + " -- 送评助手", // 分享标题
          desc: '上海辉龙阁实体店入驻上海云洲商厦，距离PCGS.NGC.公博.华夏上海办公室仅三余公里，每周两次送评及取币；客户送评，按币状态做出最佳分类，专业高效值得信赖。', // 分享描述
          link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.shop.logo, // 分享图标
        },
        function (res) {
          console.log(res, 11122);
          //这里是回调函数
        }
      );

      wx.updateTimelineShareData(
        {
          title: that.shop.shop_name + " -- 评级服务", // 分享标题
          link: fullPath, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: that.shop.logo, // 分享图标
        },
        function (res) {
          //这里是回调函数
        }
      );
    });
    wx.error(function (res) {
      console.error(res, "---------------错误信息---------------");
    });
  },
  methods: {
    async getAmount() {
      const r = await this.$axios.get("/service/getIndexAmount?shop_id=" + this.shop_id);

      this.headAmount = {
        yijieshouAmount: r.data.yijieshouAmount,
        songpingzhongAmount: r.data.songpingzhongAmount,
        yipingjiAmount: r.data.yipingjiAmount,
        yifukuanAmount: r.data.yifukuanAmount,
        yifanhuanAmount: r.data.yifanhuanAmount,
      }
    },
    toDingdanLiebiao(status) {
      this.$router.push('/serviceList/' + this.shop_id + '?status=' + status)
    },
    copy() {
      let that = this;
      let e = new Clipboard(".copy", {
        target: function (trigger) {
          return document.getElementById("text");
        }
      });
      e.on("success", function (t) {
        t.clearSelection();

        Notify({ type: 'success', message: '复制成功' });
      });
    },
    goto(id) {
      console.log(id);
      this.$router.push('/service/item/' + this.shop_id + '?id=' + id)
    },
    goto2() {
      this.$router.push('/service/desc/' + this.shop_id)
    }
  },
};
</script>